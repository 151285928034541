import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getCustomComponent } from 'components/Themes';
import { TopPageAdditionalImageCardProps } from 'lib/themes/themes';

export const TopPageAdditionalImageCard = (props: TopPageAdditionalImageCardProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'TopPageAdditionalImageCard');

  return <CustomComponent {...props} />;
};
