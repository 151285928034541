import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { ProductSummaryCardViewProps } from 'lib/themes/themes';
import { getCustomComponent } from 'components/Themes';

export const ProductSummaryCardView = (props: ProductSummaryCardViewProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'ProductSummaryCardView');

  return <CustomComponent {...props} />;
};
