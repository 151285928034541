import dynamic from 'next/dynamic';

import { Props as EditorProps } from 'components/Editor/Editor';

import styles from './TopPageCustomIntroduction.module.css';

interface Props {
  messageJSON: string;
}

const Editor = dynamic<EditorProps>(
  () => import('components/Editor/Editor').then((mod) => mod.Editor) as any,
  {
    ssr: false,
  }
);

export const TopPageCustomIntroduction = ({ messageJSON }: Props) => {
  return (
    <div className={styles['base-info__editor']}>
      <Editor data={messageJSON ? JSON.parse(messageJSON) : null} minHeight={0} />
    </div>
  );
};
