import * as React from 'react';

import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { getCustomComponent } from 'components/Themes';
import { BookingWidgetSectionHeaderProps } from 'lib/themes/themes';

export const BookingWidgetSectionHeader = (props: BookingWidgetSectionHeaderProps) => {
  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const CustomComponent = getCustomComponent(bookingWidgetTheme, 'BookingWidgetSectionHeader');

  return <CustomComponent {...props} />;
};
