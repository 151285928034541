import { useSelector } from 'react-redux';

import { selectProductSummaries } from 'lib/util/productSummaryShape';
import { CardSlider } from 'components/TopPageCustomSections/CardSlider/CardSlider';
import { ProductSummaryCard } from 'components/ProductSummaryCard/ProductSummaryCard';
import { BookingWidgetSectionHeader } from 'components/BookingWidgetSectionHeader/BookingWidgetSectionHeader';

interface Props {
  title: string;
  productIds: string[];
}

export const TopPageProductListSlider = ({ title, productIds }: Props) => {
  const allProductSummaries = useSelector(selectProductSummaries);

  const products = productIds
    .map((productId) => allProductSummaries.find((p) => p.id === productId))
    .filter((p) => Boolean(p));

  return (
    <>
      <BookingWidgetSectionHeader text={title} />
      <CardSlider
        centerNarrowContent={true}
        data={products}
        renderElement={(product) =>
          product ? <ProductSummaryCard product={product} orientation="VERTICAL" /> : null
        }
      />
    </>
  );
};
