import { Box } from '@mui/material';

import { TopPageAdditionalImageCard } from 'components/TopPageAdditionalImageCard/TopPageAdditionalImageCard';
import { TopPageAdditionalImage } from 'models/settings';
import { CardSlider } from './CardSlider/CardSlider';

interface Props {
  additionalImages: TopPageAdditionalImage[];
}

export const TopPageAdditionalImagesSection = ({ additionalImages }: Props) => {
  return (
    <Box mt="36px">
      <CardSlider
        centerNarrowContent={true}
        data={additionalImages}
        renderElement={(additionalImage) =>
          additionalImage ? <TopPageAdditionalImageCard additionalImage={additionalImage} /> : null
        }
      />
    </Box>
  );
};
